import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/(proper_react)/(redesign)/(public)/PublicShell.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(proper_react)/(redesign)/Shell.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(proper_react)/images/monitor-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/app/(proper_react)/images/mozilla-logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["SignInButton"] */ "/app/src/app/components/client/SignInButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TelemetryLink"] */ "/app/src/app/components/client/TelemetryLink.tsx");
